import { auth } from '@/firebaseConfig'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateProfile } from "firebase/auth";
import { db } from '@/firebaseConfig'
import { doc, getDoc } from "firebase/firestore"; 

export default {
    state: {
        user: {
            loggedIn: false,
            data: null
        },
        company: undefined,
      },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value
        },
        SET_USER(state, data) {
            state.user.data = data
        },
        SET_COMPANY(state, name) {
            state.company = name
        },
    },
    actions: {
        async register(context, { email, password, name}){
            const { user } = await createUserWithEmailAndPassword(auth, email, password)
            console.log(`User ${user.uid} created`)
            await updateProfile(user, {
                displayName: name
            });
            console.log("User profile updated")
            context.commit('SET_USER', user)
        },
  
        async logIn(context, { email, password }){
            try {
                const response = await signInWithEmailAndPassword(auth, email, password)
                if (response) {
                    context.commit('SET_USER', response.user)
                    console.log("User logged in and committed to state")
                } else {
                    throw new Error('Login failed')
                }
            } catch (error) {
                console.error('Error in logIn action:', error)
                if (error.code === 'auth/user-not-found') {
                    alert('Usuário não encontrado. Verifique o e-mail e tente novamente.')
                } else if (error.code === 'auth/wrong-password') {
                    alert('Senha incorreta. Por favor, tente novamente.')
                } else {
                    alert('Erro ao fazer login: ' + error.message)
                }
                throw error
            }
        },
  
        async logOut(context){
            await signOut(auth)
            context.commit('SET_USER', null)
        },

        async setCompany(context,name){
            const docRef = doc(db, "company/"+name)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                let company = docSnap.data()
                company['name'] = name
                context.commit("SET_COMPANY", company)
                console.log(company)
                return true
            }
            context.commit("SET_COMPANY", undefined);
            return false
        },
  
        async fetchUser(context, user) {
            context.commit("SET_LOGGED_IN", user !== null);
            if (user) {
                context.commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email
                });
            } else {
                context.commit("SET_USER", null);
            }
        },
    },
    getters: {
        user(state){
            return state.user
        },
        company(state){
            return state.company
        },
    }
}