import { db } from '@/firebaseConfig'
import { collection, getCountFromServer, addDoc, doc, getDoc, getDocs, updateDoc, serverTimestamp, query, where, orderBy, limit } from "firebase/firestore"; 

const company = 'intel'

export default {
    state: {
        user: {
            loggedIn: false,
            data: null
        },
        company: undefined,
      },
    mutations: {
        SET_COMPLAINTS_STATISTICS(state, value) {
            state.complaintsStatistics = value
        },
        SET_COMPLAINT_DETAIL(state, data) {
            state.complaintDetail = data
        },
        SET_LAST_COMPLAINTS(state, data) {
            state.lastComplaints = data
        }
    },
    actions: {
        async createComplaint(context,complaint) {
            try {
                complaint['createdAt'] = serverTimestamp()
                const new_date = new Date()
                new_date.getTime()
                // const test = {'abc': 1, 'teste': 'xyz'}
                console.log(complaint)
                const docRef = await addDoc(collection(db, "company/"+company+"/complaint"), complaint);
                console.log("Document written with ID: ", docRef.id);
                return docRef.id
            } catch (e) {
                console.error("Error adding document: ", e);
                return false
            }
        },

        async closeComplaint(context,id) {
            try {
                const ref = doc(db, "company/"+company+"/complaint", id);
                await updateDoc(ref, {
                    closedAt: serverTimestamp()
                })
            } catch (e) {
                console.error("Error updating document: ", e);
                return false
            }
        },

        async fetchComplaintById(context, id) {
            const docRef = doc(db, "company/"+company+"/complaint", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
            console.log("Document data:", docSnap.data())
            context.commit("SET_COMPLAINT_DETAIL", docSnap.data())
            } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            }
        },

        async fetchComplaintsStatistics(context) {
            try {
                const now_timestamp = serverTimestamp()

                let statistics = {}
                // count total complaints
                const coll = collection(db, "company/"+company+"/complaint");
                var snapshot = await getCountFromServer(coll)
                statistics['total'] = snapshot.data().count
                
                // count opened complaints
                var q = query(coll, where("closedAt", "==", null))
                snapshot = await getCountFromServer(q)
                statistics['opened'] = snapshot.data().count

                const t = now_timestamp-259200
                q = query(coll, where("createdAt", ">", t)) //259200 = 30 days
                snapshot = await getCountFromServer(q)
                statistics['last_30_days'] = snapshot.data().count

                q = query(coll, where("fato", "==", "Outros"))
                snapshot = await getCountFromServer(q)
                // context.commit("SET_OPENED_COMPLAINTS", snapshot.data().count)
                // console.log('statistics',statistics)
                context.commit("SET_COMPLAINTS_STATISTICS", statistics);
                
                return true
                
            } catch (e) {
                console.error("Error adding document: ", e);
                return false
            }
        },

        async fetchLastComplaints(context) {
            try {
                // get all complaints
                let lastComplaints = {}
                var q = query(collection(db, "company/"+company+"/complaint"), orderBy("createdAt", "desc"), limit(5));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    lastComplaints[doc.id] = doc.data()
                });
                // console.log('lastComplaints',lastComplaints)
                context.commit("SET_LAST_COMPLAINTS", lastComplaints)

                return true
            } catch (e) {
                console.error("Error adding document: ", e);
                return false
            }
        },

        async getComplaintDetail(context, id) {
            try {
                const docRef = doc(db, "company/"+company+"/complaint", id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    console.log("Document data:", docSnap.data());
                } else {
                    // docSnap.data() will be undefined in this case
                    console.log("No such document!");
                }
                context.commit("SET_COMPLAINT_DETAIL", docSnap.data());
            } catch (e) {
                console.error("Error getting document: ", e);
                return false
            }
        },
    },
    getters: {
        complaintsStatistics(state){
            return state.complaintsStatistics
        },
        lastComplaints(state){
            return state.lastComplaints
        },
        complaintDetail(state){
            return state.complaintDetail
        }
    }
}