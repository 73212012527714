<template>
    <div  style="margin: 60px; display: flex; flex-direction: row; justify-content: center;">
      <div>
        <form @submit.prevent="login">
            <h3>Entrar</h3>

            <label for="username">Usuário</label>
            <input type="email" placeholder="E-mail" id="email" name="email" value required autofocus v-model="email">

            <label for="password">Senha</label>
            <input type="password" placeholder="Password" id="password" name="password" required v-model="password">

            <button>Entrar</button>
            <div v-if="error" class="alert alert-danger">{{error}}</div>
        </form>
        <button type="button" @click="resetPassword" style="margin-top: 10px;">Esqueci a senha</button>
        <div v-if="resetPasswordMessage" class="alert alert-info" style="margin-top: 10px;">{{ resetPasswordMessage }}</div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { useStore } from 'vuex'
  import { sendPasswordResetEmail } from "firebase/auth";
  import { useRouter } from 'vue-router'
  import { auth } from '@/firebaseConfig'
  
  export default {
    inheritAttrs:false,
    name: "LoginComponent",
      setup() {
      const email = ref('')
      const password = ref('')
      const error = ref(null)
      const resetPasswordMessage = ref(null)
  
      const store = useStore()
      const router = useRouter()
  
       const login = async () => {
        console.log('Login method called')
        try {
          console.log('Tentando fazer login com', { email: email.value, password: password.value })
          await store.dispatch('logIn', {
            email: email.value,
            password: password.value,
          })
          console.log('Login bem-sucedido')
          router.push('/principal')
        } catch (err) {
          console.error('Erro ao fazer login:', err)
          error.value = err.message
        }
    }

    const resetPassword = async () => {
      if (!email.value) {
        resetPasswordMessage.value = 'Por favor, insira seu e-mail para resetar a senha.'
        return
      }
      try {
        console.log('Enviando e-mail de redefinição de senha para', email.value)
        await sendPasswordResetEmail(auth, email.value)
        resetPasswordMessage.value = 'E-mail de redefinição de senha enviado. Verifique sua caixa de entrada.'
      } catch (err) {
        console.error('Erro ao enviar e-mail de redefinição de senha:', err)
        resetPasswordMessage.value = err.message || 'Erro desconhecido'
      }
    }

    return { email, password, error, login, resetPassword }
  },
}
  </script>


<style scoped>
  *,
  *:before,
  *:after{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  }
  body{
  background-color: #080710;
  }
  .background{
  width: 430px;
  height: 620px;
  position: absolute;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 50%;
  }
  .background .shape{
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
  }
  .shape:first-child{
  background: linear-gradient(
    #1845ad,
    #23a2f6
  );
  left: -80px;
  top: -80px;
  }
  .shape:last-child{
  background: linear-gradient(
    to right,
    #035762,
    #09bfd7
  );
  right: -30px;
  bottom: -80px;
  }
  form{
  margin-top: 140px;
  height: 620px;
  width: 400px;
  background-color: rgba(255,255,255,0.13);
  position: relative;
  /* transform: translate(-50%,-50%); */
  /* top: 50%;
  left: 50%; */
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 8px 8px 10px 8px #BACCDE8A;
  padding: 50px 35px;
  }
  form *{
  font-family: 'Poppins',sans-serif;
  color: #191e23;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
  }
  form h3{
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
  }
  
  label{
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  }
  input{
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255,255,255);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  }
  ::placeholder{
  color: #191e23;
  }
  button{
  margin-top: 50px;
  width: 100%;
  background-color: #191e23;
  color: white;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  }
  .social{
  margin-top: 30px;
  display: flex;
  }
  .social div{
  background: red;
  width: 150px;
  border-radius: 3px;
  padding: 5px 10px 10px 5px;
  background-color: rgba(255,255,255,0.27);
  color: #eaf0fb;
  text-align: center;
  }
  .social div:hover{
  background-color: rgba(255,255,255,0.47);
  }
  .social .fb{
  margin-left: 25px;
  }
  .social i{
  margin-right: 4px;
  }

  @media (max-width: 1800px) {

  form {
    margin-top: 160px;
  }

}
  
</style>