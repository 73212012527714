<template>
  <div style="margin: 60px; display: flex; flex-direction: row; justify-content: center; margin-top: 140px;" class="main-container">
    <form @submit.prevent="Register">
      <h3>Registro</h3>
      <label for="name">Nome</label>
      <input
        type="text"
        placeholder="Nome completo"
        id="name"
        v-model="name"
        required
      />

      <label for="email">E-mail</label>
      <input
        type="email"
        placeholder="E-mail"
        id="email"
        v-model="email"
        required
      />

      <label for="password">Senha</label>
      <input
        type="password"
        placeholder="Senha"
        id="password"
        v-model="password"
        required
      />

      <label for="password1">Confirmação de senha</label>
      <input
        type="password"
        placeholder="Confirmação de senha"
        id="password1"
        v-model="password1"
        required
      />

      <button type="submit">Registrar</button>
      <div v-if="error" class="alert alert-danger">{{ error }}</div>
      <button @click="$router.push('/entrar')">Já tenho registro</button>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: "RegisterComponent",
  setup() {
    const name = ref('')
    const email = ref('')
    const password = ref('')
    const password1 = ref('')
    const error = ref(null)

    const store = useStore()
    const router = useRouter()

    const Register = async () => {
      if (password.value !== password1.value) {
        error.value = 'Confirmação de senha diferente da senha escolhida'
        return
      }

      try {
        await store.dispatch('register', {
          email: email.value,
          password: password.value,
          name: name.value,
        })
        router.push('/principal')
      } catch (err) {
        console.error('Erro ao registrar:', err)
        error.value = err.message
      }
    }

    return { Register, name, email, password, password1, error }
  },
}
</script>

  <style scoped>
  *,
  *:before,
  *:after{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  }
  body{
  background-color: #080710;
  }
  .background{
  width: 430px;
  height: 620px;
  position: absolute;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 50%;
  }
  .background .shape{
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
  }
  .shape:first-child{
  background: linear-gradient(
    #1845ad,
    #23a2f6
  );
  left: -80px;
  top: -80px;
  }
  .shape:last-child{
  background: linear-gradient(
    to right,
    #035762,
    #09bfd7
  );
  right: -30px;
  bottom: -80px;
  }
  form{
  height: auto;
  width: 400px;
  background-color: rgba(255,255,255,0.13);
  position: relative;
  /* transform: translate(-50%,-50%); */
  /* top: 50%;
  left: 50%; */
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 8px 8px 10px 8px #BACCDE8A;
  padding: 50px 35px;
  }
  form *{
  font-family: 'Poppins',sans-serif;
  color: #191e23;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
  }
  form h3{
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
  }
  
  label{
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  }
  input{
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255,255,255);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  }
  ::placeholder{
  color: #191e23;
  }
  button{
  margin-top: 20px;
  width: 100%;
  background-color: #191e23;
  color: white;
  padding: 10px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  }
  .social{
  margin-top: 30px;
  display: flex;
  }
  .social div{
  background: red;
  width: 150px;
  border-radius: 3px;
  padding: 5px 10px 10px 5px;
  background-color: rgba(255,255,255,0.27);
  color: #eaf0fb;
  text-align: center;
  }
  .social div:hover{
  background-color: rgba(255,255,255,0.47);
  }
  .social .fb{
  margin-left: 25px;
  }
  .social i{
  margin-right: 4px;
  }

  @media (max-width: 1800px) {

  form {
    padding: 10px 35px;
    margin-top: 70px;
    margin-bottom: 50px;
  }

}
  
  </style>