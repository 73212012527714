<template>

  <div class="main-container">
      <div class="due-container">
        <div class="text-container">
          <p>
            Nós somos a <span style="font-weight: bold;"> Due</span>, uma empresa especializada na implementação, revisão e melhoria de soluções inteligentes de Governança, Riscos, 
            Compliance e Sustentabilidade, com o objetivo de gerar valor e desenvolver a cultura organizacional de cada cliente, de forma próxima e 
            personalizada, construindo pontes para que, com nosso know-how, atinjam seus objetivos.
          </p>
          <p style="margin-top: 20px;">
            Oferecemos à sua empresa um serviço especializado e personalizado de estruturação e implementação de auditorias e controles internos para manter 
            um ambiente seguro em suas operações.
          </p>
        </div>
      </div>
  </div>

</template>

<script>
export default {
  name: 'home-view',
  props: {
    msg: String
  }
}
</script>

<style scoped>

.main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
}

.due-container {
  background-image: url('../../assets/0x0.jpg');
  width: 100%; 
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.text-container {
  width: 60%;
  box-shadow: 8px 8px 10px 8px #BACCDE8A;
  border-radius: 16px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  opacity: 0.7;
}

.text-container p {
  width: 100%;
  font-size: 24px;
  color: #000;
  text-align: justify;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

@media (max-width: 1800px) {
  .due-container {
    width: 100%;
  }

  .text-container {
    width: 90%;
    margin-top: 20px;
  }

  .text-container p {
    width: 100%;
    color: #000;
    text-align: justify;
  }
}

@media (max-width: 1000px) {
  .due-container {
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 80px;
  }

  .text-container p {
    font-size: 16px;
  }

  .due-container img {
    margin-bottom: 20px;
  }
}

@media (max-width: 450px) {
  .main-container {
    margin-left: 50px;
  }
}
</style>