import Vuex from 'vuex'

import auth from './auth.js'
import complaint from './complaint.js'

// import createPersistedState from "vuex-persistedstate"

const store = new Vuex.Store({
    modules: {
        auth,
        complaint
    },
    // plugins: [createPersistedState()]
})

export default store
