import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { aliases, mdi } from 'vuetify/iconsets/mdi'

import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

import store from './store/index'

const icons = {
    defaultSet: 'mdi',
    aliases,
    sets: {
        mdi,
    }
}


const vuetify = createVuetify({
    components,
    directives,
    icons,
})

createApp(App).use(router).use(vuetify).use(store).mount('#app')