<template>
    <nav class="headerS" style="margin-bottom: 30px;">
        <v-row class="headerComps">
            <a href="/">
                <img alt="Due logo" src="@/assets/logo-due.png" class="logoHome">
            </a>
            <a href="/intel">
                <img alt="Client logo" :src="logoCompanyUrl" class="logoHome">
            </a>
            <div>
                <a class="menuS" :href="dataProtectionUrl">Proteção de dados</a>
                <a class="menuS" >Programa de integridade</a>
                <a class="menuS" >Código de conduta</a>
            </div>
            <div>
                <a :href="loginUrl">
                    <button class="entrar-button">
                        <p>Entrar</p>
                    </button>
                </a>
            </div>
        </v-row>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            logoCompanyUrl: require("@/assets/logo-"+this.$route.params.company+".png"),
            loginUrl: "/"+this.$route.params.company+"/entrar",
            complaintUrl: "/"+this.$route.params.company+"/",
            dataProtectionUrl: "/"+this.$route.params.company+"/protecao-de-dados"
        }
    }
}
</script>

<style scoped>
.entrar-button {
        width: 164px;
        height: 36px;
        background: #191e23;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 18px;
        font-weight: 600;
        margin-right: 20px;
    }

    .entrar-button:hover {
        transform: scale(1.05);
    }

    .entrar-button p {
        margin-bottom: 0;
    }

    .menuS{
        margin-right: 30px;
        text-decoration: none;
        font-size: 22px;
        font-family: 'Montserrat', sans-serif;
    }

    a:hover{
        transform: scale(1.05) !important;
    }
    .logoHome{
        max-height: 60px;
        margin-right: 60px;
    }
    .headerComps{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    nav {
    padding: 30px;
    }
    nav a {
    font-weight: 300;
    color: #191e23;
    }
    nav a.router-link-exact-active {
    color: #191e23;
    }
    .dropbtn {
        font-weight: 300;
        background-color: none;
        color: #161616;
        border: none;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 200px;
        z-index: 1;
    }

    .dropdown-content a {
        color: 191e23;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }
    .dropdown-content a:hover {background-color: #ddd;}
    .dropdown:hover .dropdown-content {display: block;}

      @media (max-width: 1200px) {
        .entrar-button {
            margin-right: 0px;
        }
    }
</style>