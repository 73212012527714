<template>
    <nav class="headerS" style="margin-bottom: 30px; position: relative; z-index: 9;">
        <v-row class="headerComps">
            <a href="/">
                <img alt="Vue logo" src="../assets/logo-due.png" class="logoHome">
            </a>
            <a :href="companyUrl">
                <img alt="Client logo" :src="logoCompanyUrl" class="logoHome">
            </a>
            <a class="menuS" :href="complaintUrl">Denúncias</a>
            <a class="menuS" :href="checkUrl">Background check</a>
            <a class="menuS" :href="riskUrl">Gestão de riscos</a>
            <div style="display: flex; flex-direction: row; justify-content: center;">
                <div style="margin-left: 30px; margin-right: 10px;">
                    <v-icon size="50">mdi mdi-account-circle</v-icon>
                </div>
                <div v-if="user.data">
                    <h5>{{user.data.displayName}}</h5>
                </div>
            </div>
            <button  @click.prevent="signOut" class="btn btn-secondary">Sair</button>
        </v-row>
    </nav>
</template>

<script>

import { useRouter } from 'vue-router'
import { mapGetters, mapActions} from "vuex"
import { auth } from '../firebaseConfig'

export default {
    computed: {
        ...mapGetters(['user','complaintsResume'])
    },
    data() {
        return {
            logoCompanyUrl: require("@/assets/logo-"+this.$route.params.company+".png"),
            companyUrl: "/"+this.$route.params.company,
            complaintUrl: "/"+this.$route.params.company+"/denuncia/painel",
            checkUrl: "/"+this.$route.params.company+"/check/painel",
            riskUrl: "/"+this.$route.params.company+"/riscos/painel",
        }
    },
    methods: {
        ...mapActions(['fetchUser','logOut']),
        router: useRouter(),
        async signOut() {
            await this.logOut()
            this.$router.push('/'+this.$router.params.company+'/')
        }
    },
    mounted: async function() {
        auth.onAuthStateChanged(user => {
            this.fetchUser(user)
        console.log(this.user)
        }) 
    }
}
</script>

<style scoped>
    .menuS{
        margin-right: 30px;
        text-decoration: none;
        font-size: 22px;
    }
    .logoHome{
        max-height: 60px;
        margin-right: 60px;
    }
    .headerComps{
        display: flex;
        justify-content: center;
        align-items: baseline;
    }
    nav {
    padding: 30px;
    }
    nav a {
    font-weight: 300;
    color: #191e23;
    }
    nav a.router-link-exact-active {
    color: #191e23;
    }
    .dropbtn {
        font-weight: 300;
        background-color: none;
        color: #161616;
        border: none;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 200px;
        z-index: 1;
    }

    .dropdown-content a {
        color: 191e23;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }
    .dropdown-content a:hover {background-color: #ddd;}
    .dropdown:hover .dropdown-content {display: block;}
</style>