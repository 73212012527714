<template>
  <v-app>
    <div>
      <router-view name="NavBarDashboard"/>
      <router-view name="NavBarEmpresa"/>
      <router-view name="NavBar"/>
      <router-view name="sideMenuDashboard"/>
      <router-view/>
    </div>
    <v-footer app width="100%" :color="color" dark class="d-flex align-center justify-center">
      <div class="mr-2"><small>© 2024 DUE - Todos os direitos reservados.</small></div>
      <div class="mr-2"><small><router-link to="/termos-de-uso">Termos de Uso</router-link></small></div>
      <div class="mr-2"><small><router-link to="/politica-de-privacidade">Política de Privacidade</router-link></small></div>
    </v-footer>
  </v-app>
  <!-- <router-view name="NavBarDashboard"/>
  <router-view name="NavBarEmpresa"/>
  <router-view name="NavBar"/>
  <router-view name="sideMenuDashboard"/>
  <router-view style="min-height: 80vh; position: relative; z-index: 1;"/> -->
  <!-- <FooterBar class="footerPosition" style="z-index: 2;"/> -->
  <!-- <v-footer>
    <div style="display: flex; flex-direction: row;">
      <a href="/">
        <img alt="Vue logo" src="@/assets/logo-branca-due.png" class="logoHome">
      </a>
      <div>
        <p> Rua Imperial, 535, São Salvador, Belo Horizonte - MG  CEP: 30881-450</p>
      </div>
    </div>
    <div>
      <p>
        © Copyright 2023 - Todos os direitos reservados | Administrado por Due
      </p>
    </div>
  </v-footer> -->
</template>

<script>
// import FooterBar from '@/components/FooterBar.vue'
// import NavBar from '@/components/NavBar.vue'
// import NavBarEmpresa from './components/NavBarEmpresa.vue';
// import NavBarDashboard from './components/NavBarDashboard.vue';

export default {
  inheritAttrs:false,
  components: {
    // FooterBar,
    // NavBar,
    // NavBarEmpresa,
    // NavBarDashboard,
}
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.footerPosition{
      width: 100%;
      position: relative;
      bottom: 0;
    }
</style>
