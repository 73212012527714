import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/site/Home.vue'
import Login from '@/views/site/Login.vue';
import Register from '@/views/site/Register.vue';
import NavBar from '../components/NavBar.vue';
import NavBarEmpresa from '../components/NavBarEmpresa.vue';
import NavBarDashboard from '../components/NavBarDashboard.vue';
import sideMenuDashboard from '../components/sideMenuDashboard.vue'


const routes = [
  {
    path: '/entrar',
    name: 'login',
    components:{
      default: Login,
      NavBar
    }
  },
  {
      path: '/registrar',
      name: 'Register',
      components:{
        default: Register,
        NavBar
      }
  },
  {
    path: '/',
    name: 'Home',
    components:{
      default: Home,
      NavBar
    }
  },
  {
    path: '/:company/principal',
    name: 'LoggedHome',
    components:{
      default: () => import('@/views/client/LoggedHome.vue'),
      NavBar
    }
  },
  {
    path: '/dna',
    name: 'Dna',
    components:{
      default: () => import('@/views/site/Dna.vue'),
      NavBar
    }
  },
  {
    path: '/servicos',
    name: 'Services',
    components:{
      default: () => import('@/views/site/Services.vue'),
      NavBar
    }
  },
  // {
  //   path: '/sustentabilidade',
  //   name: 'Sestainability',
  //   components:{
  //     default: () => import('@/views/site/Sustainability.vue'),
  //     NavBar
  //   }
  // },
  {
    path: '/:company/protecao-de-dados',
    name: 'DataProtection',
    components:{
      default: () => import('@/views/client/DataProtection.vue'),
      NavBarEmpresa,
    }
  },
  {
    path: '/:company',
    name: 'CompanyHome',
    components:{
      default: () => import('@/views/complaint/Home.vue'),
      NavBarEmpresa,
    }
  },
  {
    path: '/:company/eia',
    name: 'EIA',
    components:{
      default: () => import('@/views/complaint/EIA.vue'),
      NavBarEmpresa,
    }
  },
  {
    path: '/:company/eia/form',
    name: 'EIAForm',
    components:{
      default: () => import('@/views/complaint/EIAForm.vue'),
      NavBarEmpresa,
    }
  },
  {
    path: '/:company/eia/relatorio',
    name: 'EIARelatorio',
    components:{
      default: () => import('@/views/complaint/EIARelatorio.vue'),
      NavBarEmpresa,
    }
  },
  {
    path: '/:company/eia/indicadores',
    name: 'EIAIndicadores',
    components:{
      default: () => import('@/views/complaint/EIAIndicadores.vue'),
      NavBarEmpresa,
    }
  },
  {
    path: '/:company/entrar',
    name: 'CompanyLogin',
    components:{
      default: () => import('@/views/client/Login.vue'),
      NavBarEmpresa
    }
  },
  {
    path: '/:company/denuncia',
    name: 'Complaint',
    components: {
      default: () => import('@/views/complaint/Form.vue'),
      NavBarEmpresa
    }
  },
  {
    path: '/:company/denuncia/painel',
    name: 'ComplaintDashboard',
    components:{
      default: () => import('@/views/complaint/Dashboard.vue'),
      NavBarDashboard,
      sideMenuDashboard,
    }
  },
  {
    path: '/:company/denuncia/lista',
    name: 'ComplaintList',
    components:{
      default: () => import('@/views/complaint/List.vue'),
      NavBarDashboard,
      sideMenuDashboard,
    }
  },
  {
    path: '/:company/denuncia/investigacao',
    name: 'ComplaintInvestigation',
    components:{
      default: () => import('@/views/complaint/Investigation.vue'),
      NavBarDashboard,
      sideMenuDashboard,
    }
  },
  {
    path: '/:company/denuncia/acao',
    name: 'ComplaintAction',
    components:{
      default: () => import('@/views/complaint/Action.vue'),
      NavBarDashboard,
      sideMenuDashboard,
    }
  },
  {
    path: '/:company/denuncia/usuarios',
    name: 'ComplaintUsers',
    components:{
      default: () => import('@/views/complaint/Users.vue'),
      NavBarDashboard,
      sideMenuDashboard,
    }
  },
  {
    path: '/:company/denuncia/detalhes/:id',
    name: 'ComplaintDetails',
    components:{
      default: () => import('@/views/complaint/Detail.vue'),
      NavBarEmpresa,
      sideMenuDashboard,
    }
  },
  {
    path: '/:company/denuncia/status',
    name: 'ComplaintStatus',
    components:{
      default: () => import('@/views/complaint/Status.vue'),
      NavBarEmpresa,
    }
  },
  // {
  //   path: '/:company/denuncia/status/:id',
  //   name: 'ComplaintStatus',
  //   components:{
  //     default: () => import('@/views/complaint/Status.vue'),
  //     NavBarEmpresa,
  //   }
  // },
  {
    path: '/:company/check/painel',
    name: 'BackgroundCheck',
    components: {
      default: () => import('@/views/check/Home.vue'),
      NavBarDashboard
    }
  },
  {
    path: '/:company/riscos/painel',
    name: 'RiskManage',
    components: {
      default: () => import('@/views/risk/Home.vue'),
      NavBarDashboard
    }
  },
  {
    path: '/:company/sugestao',
    name: 'Sugestion',
    components:{
      default: () => import('@/views/client/Sugestion.vue'),
      NavBarEmpresa,
    }
  },
  // {
  //   path: '/cliente/:company',
  //   name: 'Cliente',
  //   component: () => import('../views/ClientView.vue')
  // },
  {
    path: '/contato',
    name: 'Leads',
    components:{
      default: () => import('@/views/site/Leads.vue'),
      NavBar,
    }
  },
  {
    path: '/termos-de-uso',
    name: 'termos-de-uso',
    component: () => import('../views/TermosDeUso.vue')
  },
  {
    path: '/politica-de-privacidade',
    name: 'politica-de-privacidade',
    component: () => import('../views/PoliticaDePrivacidade.vue')
  },
  {
    path: '/canal-de-denuncia',
    name: 'canal-de-denuncia',
    component: () => import('../views/complaint/Report.vue')
  },
]

const router = createRouter({
  base: '/site/' || '/',
  mode: 'history',
  history: createWebHistory(),
  // hash: true,
  routes
})

export default router
