<template>
    <nav class="headerS">
        <div class="navbar-main">
            <div class="headerComps">
                    <a href="/">
                        <img alt="Vue logo" src="@/assets/logo-due.png" class="logoHome">
                    </a>
                <!-- <div class="dropdown">
                    <button class="dropbtn menuS" to="/">Início</button>
                    <div class="dropdown-content">
                        <a href="/protecao-de-dados">Proteção de dados</a>
                        <a href="/cliente/intel">Compliance</a>
                        <a href="/protecao-de-dados">Proteção de dados</a>
                        <a href="/cliente/intel">Compliance</a>
                    </div>
                </div> -->
                <div class="menu-navbar">
                    <a class="menuS" href="/">Nossa empresa</a>
                    <a class="menuS" href="/dna">Nosso DNA</a>
                    <a class="menuS" href="/servicos">Nossos serviços</a>
                    <!-- <a class="menuS" href="/sustentabilidade">Sustentabilidade</a> -->
                    <a class="menuS" href="/contato">Contato</a>
                    <!-- <a class="menuS" >Trabalhe conosco</a> -->
                </div>
            </div>
            <div class="buttons">
                <a href="/entrar">
                    <button class="entrar-button">
                        <p>Entrar</p>
                    </button>
                </a>
            </div>
        </div>
    </nav>
</template>

<!-- <script>
import { useRouter } from 'vue-router'
export default {
    methods: {
        router: useRouter()
    }
}
</script> -->

<style scoped>
    .menuS{
        margin-right: 30px;
        text-decoration: none;
        font-size: 22px;
        font-family: 'Montserrat', sans-serif;
    }

    .menuS:hover {
        transform: scale(1.05);
    }
    .logoHome{
        max-height: 80px;
        margin-right: 60px;
        margin-top: 20px;
    }
    .headerComps{
        display: flex;
        align-items: center;
        margin-left: 32px;
    }

    .headerComps img {
        margin-bottom: 20px;
        margin-right: 0 !important;
    }

    .menu-navbar {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 30px;
    }

    .entrar-button {
        width: 164px;
        height: 36px;
        background: #191e23;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 18px;
        font-weight: 600;
        margin-right: 20px;
    }

    .entrar-button:hover {
        transform: scale(1.05);
    }

    .entrar-button p {
        margin-bottom: 0;
    }

    .buttons {
        display: flex;
        margin-left: 20px;
        margin-right: 10px;
    }

    .navbar-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80vw;
    }

    .headerS {
        padding-left: 0;
        display: flex;
        justify-content: center;
        background-color: #fff;
        position: fixed;
        width: 100%;
        z-index: 5;
        height: 120px;
    }

    nav {
    padding: 30px;
    }
    nav a {
    font-weight: 300;
    color: #191e23;
    }
    nav a.router-link-exact-active {
    color: #191e23;
    }
    .dropbtn {
        font-weight: 300;
        background-color: none;
        color: #161616;
        border: none;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 200px;
        z-index: 1;
    }

    .dropdown-content a {
        color: 191e23;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }
    .dropdown-content a:hover {background-color: #ddd;}
    .dropdown:hover .dropdown-content {display: block;}

    @media (max-width: 1200px) {
        .headerS {
            height: 200px;
        }

        .menuS {
            font-size: 16px;
        }

        .navbar-main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            width: 100vw;
        }

        .buttons {
            display: flex;
            justify-content: center;
            width: 100vw;
            margin-left: 62px;
        }
    }

    @media (max-width: 450px) {
        .headerS {
            width: 130vw !important;
        }
        .menuS {
            font-size: 14px;
            margin-right: 5px;
            flex-wrap: wrap;
        }

        .navbar-main {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100vw;
        }

        .buttons {
            display: flex;
            justify-content: center;
            width: 100vw;
            margin-left: 62px;
        }

        .flag {
            margin-left: 10px !important;
        }
    }

</style>