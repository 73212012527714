<template>
    <v-app style="height: 0;">
        <v-navigation-drawer :width="230" style="z-index: 0; padding-top: 190px;" :rail="rail" @click="rail = !rail">
            <v-list density="compact" nav>
                <v-list-item prepend-icon="mdi mdi-home-outline" link title="Home" @click="$router.push('/'+$route.params.company+'/principal')"></v-list-item>
                <v-list-item prepend-icon="mdi mdi-view-dashboard-edit-outline" link title="Dashboard" @click="$router.push('/'+$route.params.company+'/denuncia/painel')"></v-list-item>
                <v-list-item prepend-icon="mdi mdi-file-document-multiple-outline" link title="Denúncias" @click="$router.push('/'+$route.params.company+'/denuncia/lista')"></v-list-item>
                <v-list-item prepend-icon="mdi mdi-list-status" link title="Status da investigação" @click="$router.push('/'+$route.params.company+'/denuncia/investigacao')"></v-list-item>
                <v-list-item prepend-icon="mdi mdi-human-male-board" link title="Plano de ação" @click="$router.push('/'+$route.params.company+'/denuncia/acao')"></v-list-item>
                <v-list-item prepend-icon="mdi mdi-account" link title="Usuários" @click="$router.push('/'+$route.params.company+'/denuncia/usuarios')"></v-list-item>
                <!-- <v-divider></v-divider>
                <v-list-item prepend-icon="mdi mdi-cog-outline" link title="Configurações"></v-list-item> -->
            </v-list>
        </v-navigation-drawer>
    </v-app>
</template>
<script>

export default {
    name: 'sideMenuDashboard',
    data: ()=> ({
        rail: true
    })
}
</script>